import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import {
  currentMarkerSelector,
  currentPhotoIndexSelector,
  shapeInProgressSelector,
} from '../../../redux/marks/currentMarkingSlice';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';
import { KeyCodes } from '../../../shared-logic/enums';
import { overrideAltKey } from '../../../shared-logic/keyFunctions';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { disableShortcutsSelector } from '../../../redux/labelingTool/labelingToolSlice';

const NavigationKeys = ({ goToNextImagePairByMode, handleNewNavigation }) => {
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentMarker = useSelector(currentMarkerSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const shapeInProgress = useSelector(shapeInProgressSelector);
  const disableShortcuts = useSelector(disableShortcutsSelector);

  const dependenciesArray = [
    currentPhotoIndex,
    currentMarker,
    shapeInProgress,
    disableShortcuts,
  ];
  const NUM_ADVANCE = 20;
  const NUM_BACK = -20;

  overrideAltKey.add(KeyCodes.ARROW_LEFT);

  useEffect(() => {
    return () => overrideAltKey.remove(KeyCodes.ARROW_LEFT);
  }, []);

  useHotkeys(
    'ctrl+right',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('ctrl+right clicked');

        if (isTooth(taskLevel)) {
          handleNewNavigation(NUM_ADVANCE);
        } else {
          goToNextImagePairByMode(1);
        }
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'ctrl+left',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('ctrl+left clicked');
        if (isTooth(taskLevel)) {
          handleNewNavigation(NUM_BACK);
        } else {
          goToNextImagePairByMode(-1);
        }
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'alt+right',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('alt+right clicked');
        handleNewNavigation(NUM_ADVANCE);
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'alt+left',
    (e) => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('alt+left clicked');
        handleNewNavigation(NUM_BACK);
        e.preventDefault();
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'right',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('right clicked');
        handleNewNavigation(1);
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'left',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('left clicked');
        handleNewNavigation(-1);
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'home',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('home clicked');
        handleNewNavigation('first');
      }
    },
    [...dependenciesArray]
  );

  useHotkeys(
    'end',
    () => {
      if (!disableShortcuts) {
        userActionLogs.addActionLog('end clicked');
        handleNewNavigation('last');
      }
    },
    [...dependenciesArray]
  );

  return null;
};

export default NavigationKeys;
