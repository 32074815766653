import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import modalStyles from '../cancelTaskDialog/cancelTaskDialog.module.css';
import {
  setDisableShortcuts,
  setConfirmPopUpEnabled,
} from '../../../../redux/labelingTool/labelingToolSlice';
import { useDispatch } from 'react-redux';

const { customUi, dialogBtns, dialogBtn } = modalStyles;

const CompleteTaskDialog = ({ completeTask }) => {
  const dispatch = useDispatch();

  const dispatchShortCuts = (value) => dispatch(setDisableShortcuts(value));

  useEffect(() => {
    let closeCompleteTask = null;
    confirmAlert({
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        closeCompleteTask = onClose;
        return (
          <div className={customUi}>
            <section>
              <h3>Mark case completed</h3>
              <p>Are you sure you want to complete the case?</p>
            </section>
            <footer className={dialogBtns}>
              <button
                className={dialogBtn}
                onClick={() => {
                  completeTask(onClose);
                  dispatch(setConfirmPopUpEnabled(false));
                  dispatchShortCuts(true);
                  onClose();
                }}
              >
                Ok
              </button>
              <button
                className={dialogBtn}
                onClick={() => {
                  dispatchShortCuts(false);
                  dispatch(setConfirmPopUpEnabled(false));
                  onClose();
                }}
              >
                Cancel
              </button>
            </footer>
          </div>
        );
      },
    });

    return () => {
      if (closeCompleteTask) {
        closeCompleteTask();
      }
    };
  });
  return null;
};

export default CompleteTaskDialog;
