import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';

const EditSelectFindings = ({
  findingsList,
  selectedFindings,
  setSelectedFindings,
  preSelectedFindings,
}) => {
  const handleSelectFinding = (findingId) => {
    if (selectedFindings.includes(findingId)) {
      setSelectedFindings(selectedFindings.filter((id) => id !== findingId));
    } else {
      setSelectedFindings([...selectedFindings, findingId]);
    }
  };

  return (
    <>
      <List>
        {findingsList?.map((finding) => {
          const labelId = `checkbox-list-label-${finding.FindingId}`;

          return (
            <ListItem key={finding.FindingId} disablePadding>
              <ListItem dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    onChange={() => handleSelectFinding(finding?.FindingId)}
                    checked={
                      preSelectedFindings?.includes(finding.FindingId) ||
                      selectedFindings?.includes(finding.FindingId)
                    }
                    disabled={preSelectedFindings?.includes(finding.FindingId)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={finding.FindingName}
                  secondary={`Details: ${finding.Details.map(
                    (d) => d.name
                  ).join(', ')}.`}
                />
              </ListItem>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default EditSelectFindings;
