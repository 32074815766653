import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { msalAppConfig } from './config/msalAppConfig';
import { getMsalPublicClientApplication } from './config/msalConfig';
import { awsLabelingAppConfig } from './config/awsLabelingAppConfig';

awsLabelingAppConfig.init().then(() => {
  msalAppConfig.init().then(() => {
    getMsalPublicClientApplication().then((publicClientApplication) => {
      ReactDOM.render(
        <MsalProvider instance={publicClientApplication}>
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>
        </MsalProvider>,
        document.getElementById('root')
      );
    });

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
});
