import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import {
  tier2NavigationModeOptions,
  ASSISTED_WORKS,
  MIN_LABELERS_NUMBER,
  MAX_LABELERS_NUMBER,
} from '../../SharedLogic';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { RichTextEditor } from '../../../../../components';
import styles from './CampaignSettings.module.css';
import { LabeledSwitch } from '../../../Components';

const CampaignSettings = ({
  campaignsList,
  campaignName,
  setCampaignName,
  campaignOwner,
  setCampaignOwner,
  campaignGroup,
  setCampaignGroup,
  campaignsGroups,
  isHidden,
  setIsHidden,
  numberOfTier1Labelers,
  setNumberOfTier1Labelers,
  tier2NavigationMode,
  setTier2NavigationMode,
  assistedWork,
  setAssistedWork,
  aiAsAdditionalTier1,
  setAiAsAdditionalTier1,
  disableAutoCorrection,
  setDisableAutoCorrection,
  campaignGuidance,
  handleChangeCampaignGuidance,
}) => {
  return (
    <>
      <TextField
        required
        label="Campaign Name"
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
      />

      <TextField
        required
        label="Campaign Owner"
        value={campaignOwner}
        onChange={(e) => setCampaignOwner(e.target.value)}
      />

      <AutocompleteOrCreateNew
        selectedValue={campaignGroup}
        setSelectedValue={setCampaignGroup}
        values={campaignsGroups}
        fieldlabel="Campaign Group"
      />

      <TextField
        required
        label="Number of Tier1 Labelers"
        value={numberOfTier1Labelers}
        onChange={(e) => setNumberOfTier1Labelers(e.target.value)}
        type="number"
        inputProps={{
          min: MIN_LABELERS_NUMBER,
          max: MAX_LABELERS_NUMBER,
          step: 1,
        }}
        onKeyDown={(e) => e.preventDefault()}
      />

      <FormControl>
        <InputLabel id="select-tier2-navigation-mode-label">
          Tier2 Navigation Mode
        </InputLabel>
        <Select
          labelId="select-tier2-navigation-mode-label"
          required
          label={'Tier2 Navigation Mode'}
          value={tier2NavigationMode}
          onChange={(e) => setTier2NavigationMode(e.target.value)}
        >
          {tier2NavigationModeOptions?.map((option) => (
            <MenuItem required key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="select-assisted-work-label">Assisted Work</InputLabel>
        <Select
          labelId="select-assisted-work-label"
          required
          label={'Assisted Work'}
          value={assistedWork}
          onChange={(e) => setAssistedWork(e.target.value)}
        >
          {ASSISTED_WORKS?.map((option) => (
            <MenuItem required key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LabeledSwitch
        checked={disableAutoCorrection}
        setChecked={setDisableAutoCorrection}
        label={'Disable Auto Correction'}
      />

      <LabeledSwitch
        checked={aiAsAdditionalTier1}
        setChecked={setAiAsAdditionalTier1}
        label="AI as additional Tier1"
      />

      <LabeledSwitch
        checked={isHidden}
        setChecked={setIsHidden}
        label={'Hidden'}
      />

      <FormControl>
        <InputLabel shrink>Campaign Guidance</InputLabel>
        <RichTextEditor
          className={styles.richTextEditor}
          value={campaignGuidance}
          onChange={handleChangeCampaignGuidance}
          placeHolder="Campaign Guidance..."
        />
      </FormControl>
    </>
  );
};

export default CampaignSettings;
