import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { USER_ROLES } from '../../SharedLogic';
import styles from './SelectUsers.module.css';

const ASC_ORDER = 'asc';
const DESC_ORDER = 'desc';
const USER_ID_CELL = 'UserId';
const GROUP_CELL = 'Group';

const EditSelectUsers = ({
  selectedUsersAndRoles,
  setSelectedUsersAndRoles,
  usersSortOrder,
  setUsersSortOrder,
  groupsSortOrder,
  setGroupsSortOrder,
  sortBy,
  setSortBy,
  selectedFilteredGroups,
  setSelectedFilteredGroups,
  preSelectedUsersAndRoles,
}) => {
  const sortTable = useCallback(() => {
    let sortOrder = usersSortOrder;
    if (sortBy === GROUP_CELL) {
      sortOrder = groupsSortOrder;
    }
    return selectedUsersAndRoles.sort((a, b) => {
      if (sortOrder === ASC_ORDER) {
        return a[sortBy].localeCompare(b[sortBy]);
      } else {
        return b[sortBy].localeCompare(a[sortBy]);
      }
    });
  }, [groupsSortOrder, selectedUsersAndRoles, sortBy, usersSortOrder]);

  const getUsersRolesListData = useCallback(() => {
    const sortedUsers = sortTable();
    return sortedUsers.map((user) => ({
      label: user.UserId,
      value: user.UserId,
      group: user.Group,
      roles: user.roles,
    }));
  }, [sortTable]);

  const usersRolesListData = useMemo(
    () => getUsersRolesListData(),
    [getUsersRolesListData]
  );

  const filteredUsers = useMemo(() => {
    return selectedFilteredGroups.length > 0
      ? usersRolesListData.filter((user) =>
          selectedFilteredGroups.includes(user.group)
        )
      : usersRolesListData;
  }, [usersRolesListData, selectedFilteredGroups]);

  const handleRoleSelected = (event, userId, roleIndex) => {
    const updatedUsersRoles = [...selectedUsersAndRoles];

    const originalUserIndex = updatedUsersRoles.findIndex(
      (user) => user.UserId === userId
    );

    if (originalUserIndex !== -1) {
      updatedUsersRoles[originalUserIndex].roles[roleIndex].checked =
        event.target.checked;
      setSelectedUsersAndRoles(updatedUsersRoles);
    }
  };

  const toggleSortOrder = (cell) => {
    setSortBy(cell);
    if (cell === USER_ID_CELL) {
      setUsersSortOrder((prevOrder) =>
        prevOrder === ASC_ORDER ? DESC_ORDER : ASC_ORDER
      );
    } else if (cell === GROUP_CELL) {
      setGroupsSortOrder((prevOrder) =>
        prevOrder === ASC_ORDER ? DESC_ORDER : ASC_ORDER
      );
    }
  };

  const uniqueGroups = useMemo(() => {
    return [...new Set(selectedUsersAndRoles.map((user) => user.Group))].sort();
  }, [selectedUsersAndRoles]);

  return (
    <>
      <Autocomplete
        multiple
        options={uniqueGroups}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option}
          </li>
        )}
        className={styles.groupFilter}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Filter by Group" />
        )}
        value={selectedFilteredGroups}
        onChange={(event, newValue) => {
          setSelectedFilteredGroups(newValue.sort());
        }}
      />

      <TableContainer
        className={`${styles.usersTable} ${styles.scrollableContainer}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                className={styles.clickableCell}
                onClick={() => toggleSortOrder(USER_ID_CELL)}
              >
                User {usersSortOrder === ASC_ORDER ? '▲' : '▼'}
              </TableCell>
              <TableCell
                className={styles.clickableCell}
                onClick={() => toggleSortOrder(GROUP_CELL)}
              >
                Group {groupsSortOrder === ASC_ORDER ? '▲' : '▼'}
              </TableCell>
              {USER_ROLES.map((role) => (
                <TableCell key={role}>{role}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((row) => (
              <TableRow key={row.value}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.group}
                </TableCell>
                {row.roles.map((role, roleIndex) => (
                  <TableCell key={role.label}>
                    <Checkbox
                      checked={
                        role.checked ||
                        preSelectedUsersAndRoles[row.label]?.includes(
                          role.label
                        )
                      }
                      disabled={preSelectedUsersAndRoles[row.label]?.includes(
                        role.label
                      )}
                      onChange={(event) =>
                        handleRoleSelected(event, row.value, roleIndex)
                      }
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EditSelectUsers;
