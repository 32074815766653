import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { ASSISTED_WORKS } from '../../SharedLogic';

const EditSelectBatches = ({
  editBatchesList,
  preSelectedCampaignBatches,
  selectedBatches,
  setSelectedBatches,
  assistedWork,
  aiAsAdditionalTier1,
}) => {
  const isValidBatchSelection = (batch) => {
    // Check if the assisted work type is 'Potential misses (AI)' or if AI is used as an additional Tier 1
    if (assistedWork === ASSISTED_WORKS[1] || aiAsAdditionalTier1) {
      // If the batch does not contain data for AI detections, return false
      if (!batch.IsDataForAIDetections) {
        return false;
      }
    }

    // Check if the assisted work type is 'Training mode'
    if (assistedWork === ASSISTED_WORKS[2]) {
      // If the batch does not contain data for ground truth labels, return false
      if (!batch.IsDataForGroundTruthLabels) {
        return false;
      }
    }

    // If all conditions are satisfied, return true
    return true;
  };

  const handleSelectBatches = (batchId) => {
    if (selectedBatches.includes(batchId)) {
      setSelectedBatches(selectedBatches.filter((id) => id !== batchId));
    } else {
      setSelectedBatches([...selectedBatches, batchId]);
    }
  };

  const LabelWithStatus = ({ label, isExists }) => {
    return (
      <span>
        {label}
        <span style={{ color: isExists ? 'green' : 'red' }}>
          ({isExists ? 'V' : 'X'})
        </span>
      </span>
    );
  };

  return (
    <>
      <List>
        {editBatchesList?.map((batch) => {
          const labelId = `checkbox-batch-label-${batch.BatchId}`;

          return (
            <ListItem key={batch?.BatchId} disablePadding>
              <ListItem role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    onClick={() => handleSelectBatches(batch.BatchId)}
                    disabled={
                      !isValidBatchSelection(batch) ||
                      preSelectedCampaignBatches?.includes(batch.BatchId)
                    }
                    checked={
                      isValidBatchSelection(batch) &&
                      (selectedBatches.includes(batch?.BatchId) ||
                        preSelectedCampaignBatches.includes(batch?.BatchId))
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={batch?.BatchName}
                  secondary={
                    <>
                      <ListItemText
                        secondary={
                          <LabelWithStatus
                            label={'AI Detections '}
                            isExists={batch?.IsDataForAIDetections}
                          />
                        }
                      />
                      <ListItemText
                        secondary={
                          <LabelWithStatus
                            label={'Ground Truth '}
                            isExists={batch?.IsDataForGroundTruthLabels}
                          />
                        }
                      />
                    </>
                  }
                />
              </ListItem>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default EditSelectBatches;
