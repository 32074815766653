import { TextField } from '@mui/material';
import React from 'react';
import DisplayNameInput from './DisplayNameInput';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { ReactComponent as NoteIcon } from '../../../Images/note_icon.svg';

const AddUserForm = ({
  securityGroupUrl,
  email,
  handleEmailChange,
  emailError,
  name,
  setName,
  group,
  setGroup,
  groupList,
}) => {
  return (
    <>
      <h5>
        <NoteIcon width={20} height={20} />
        {' First, add user to the security group '}
        <a href={securityGroupUrl} target="_blank" rel="noopener noreferrer">
          itero-rnd-labeling-web-access
        </a>
        .
      </h5>

      <TextField
        required
        label="Email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? 'Please enter a valid email' : ''}
        inputProps={{
          type: 'email',
        }}
      />
      <DisplayNameInput name={name} setName={setName} />
      <AutocompleteOrCreateNew
        selectedValue={group}
        setSelectedValue={setGroup}
        values={groupList}
        fieldlabel="Group"
        required={true}
      />
    </>
  );
};

export default AddUserForm;
