export const Materials = {
  'Not Selected': 'Not Selected',
  'PFM (Pocelain Fused Metal)': 'PFM (Pocelain Fused Metal)',
  'PFZ (Pocelain Fused Zirconia)': 'PFZ (Pocelain Fused Zirconia)',
  'All Ceramic': 'All Ceramic',
  Metal: 'Metal',
  'Full Ziroconia': 'Full Ziroconia',
  'PMMA Temporary': 'PMMA Temporary',
  'Other Temporary': 'Other Temporary',
  Ziconia: 'Ziconia',
  Ceramic: 'Ceramic',
  Composite: 'Composite',
  Natural: 'Natural',
  'Composite Core': 'Composite Core',
  'Amalgam Core': 'Amalgam Core',
  Amalgam: 'Amalgam',
  'Glass Ionomer': 'Glass Ionomer',
  'Temporary Filling': 'Temporary Filling',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Buccal: 'Buccal',
  Lingual: 'Lingual',
  'Occlusal/incisal': 'Occlusal/incisal',
};
