import * as msal from '@azure/msal-browser';
import logger from '../logger';
import { msalAppConfig } from './msalAppConfig';

let msalInstance = null;
let msalConfig = null;
let account = null;

export const getMsalConfig = () => {
  if (msalConfig !== null) {
    return msalConfig;
  }
  const config = msalAppConfig.getConfig();
  msalConfig = {
    auth: {
      clientId: config?.clientId,
      authority: `https://login.microsoftonline.com/${config?.tenantId}/`,
      redirectUri: window.location.origin,
    },
  };
  return msalConfig;
};

export const getMsalPublicClientApplication = async (relogin) => {
  if (!relogin && msalInstance !== null) {
    return msalInstance;
  }
  const config = getMsalConfig();

  msalInstance = new msal.PublicClientApplication(config);
  try {
    const response = await msalInstance.handleRedirectPromise();

    if (!response) {
      // Check if the user is already signed in
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        // If no user is signed in, trigger login
        await msalInstance.loginRedirect({ scopes: ['User.Read'] });
      }
    }
  } catch (error) {
    logger
      .error('Error Acquiring Msal Public Client Application')
      .data({ module: 'msalConfig', error })
      .end();
  }

  return msalInstance;
};

export const getUser = () => {
  if (account !== null) {
    return account;
  }
  const accounts = msalInstance.getAllAccounts();
  account = accounts.length > 0 ? accounts[0] : null;

  return account;
};

export const getToken = async () => {
  const msalInstance = await getMsalPublicClientApplication();
  const account = await getUser();
  const request = {
    scopes: ['User.Read'],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response?.idToken;
  } catch (error) {
    logger
      .error('Error acquiring token')
      .data({ module: 'msalConfig', error })
      .end();
  }
};
