export const Types = {
  'Not Selected': 'Not Selected',
  Regular: 'Regular',
  'Primary Tooth': 'Primary Tooth',
  Geminated: 'Geminated',
  'Missing Tooth': 'Missing Tooth',
  Crown: 'Crown',
  Bridge: 'Bridge',
  'Inlay / Onlay / Overlay': 'Inlay / Onlay / Overlay',
  'Implant Abutment': 'Implant Abutment',
  'Scan Body': 'Scan Body',
  Veneer: 'Veneer',
  'Prepped Tooth': 'Prepped Tooth',
  'Healing Abutment': 'Healing Abutment',
  'Irregular Position': 'Irregular Position',
  'Structure Loss': 'Structure Loss',
  'Significant Filling': 'Significant Filling',
  Caries: 'Caries',
  'Add-On': 'Add-On',
  'Tooth Wear': 'Tooth Wear',
};
