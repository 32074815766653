import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  disableEnter,
  isNullOrWhitespace,
} from '../../pages/Management/Tabs/SharedLogic';

const AutocompleteOrCreateNew = ({
  selectedValue,
  setSelectedValue,
  values,
  fieldlabel,
  required = false,
}) => {
  const [inputValue, setInputValue] = useState(selectedValue || '');
  const options = values?.sort().map((v) => ({
    value: v,
    label: v,
  }));
  const [displayValue, setDisplayValue] = useState({
    value: selectedValue,
    label: selectedValue,
  });
  useEffect(() => {
    setDisplayValue({ value: selectedValue, label: selectedValue });
  }, [selectedValue]);

  const handleValueChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setSelectedValue(newValue);
    } else if (newValue && newValue.inputValue) {
      setSelectedValue(newValue.inputValue);
    } else {
      setSelectedValue(newValue?.value);
    }
  };

  return (
    <Autocomplete
      required={required}
      value={displayValue}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      selectOnFocus
      handleHomeEndKeys
      disableClearable
      clearOnBlur
      options={options.filter((option) =>
        option.label?.toLowerCase().includes(inputValue?.toLowerCase())
      )}
      getOptionLabel={(option) => option.label || ''}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.label}
          </li>
        );
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          required={required}
          onKeyDown={disableEnter}
          {...params}
          label={fieldlabel}
        />
      )}
      filterOptions={(options, params) => {
        const inputValue = params.inputValue.trim();
        const filtered = options.filter((option) =>
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())
        );

        if (!isNullOrWhitespace(inputValue) && !filtered.length) {
          filtered.push({
            inputValue: inputValue,
            value: inputValue,
            label: `Create new "${inputValue}"`,
          });
        }

        return filtered;
      }}
    />
  );
};

export default AutocompleteOrCreateNew;
