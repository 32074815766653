import React from 'react';
import { Switch, Box } from '@mui/material';

function LabeledSwitch({ checked, setChecked, label, disabled }) {
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Box>
      <Switch checked={checked} onChange={handleChange} disabled={disabled} />
      {label}
    </Box>
  );
}

export default LabeledSwitch;
